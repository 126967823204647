import React from "react"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import { useStoreon } from "../../store/store"
import { usePageContext } from "../pageContext/pageContext"
import logo from "../../assets/logo-footer.svg"
import Copyright from "../copyright/Copyright"
import { languageConfig } from "../languageSwitcher/languageMenu"
import Menu from "../menu/menu"
import facebookIcon from "../../assets/footer/icons/facebook-icon-for-footer-sova.ai.svg"
import youtubeIcon from "../../assets/footer/icons/youtube-icon-for-footer-sova.ai.svg"
import mediumIcon from "../../assets/footer/icons/medium-icon-for-footer-sova.ai.svg"
import twitterIcon from "../../assets/footer/icons/twitter-icon-for-footer-sova.ai.svg"
import gitHubIcon from "../../assets/footer/icons/gitHub-icon-for-footer-sova.ai.svg"
import DownLoadLink from "../downloadLink/DownloadLink"

interface Props {
  pageName?: string | boolean
}

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  facebookIcon: facebookIcon,
  youtubeIcon: youtubeIcon,
  mediumIcon: mediumIcon,
  twitterIcon: twitterIcon,
  gitHubIcon: gitHubIcon,
}

const year = () => {
  return new Date().getFullYear()
}

const Footer = ({ pageName = false }: Props) => {
  const data = useStaticQuery(
    graphql`
      query {
        allStrapiFooter {
          edges {
            node {
              name
              content {
                language
                content {
                  formPlaceholder
                  link
                  href
                  p
                  prehead
                  subsribe
                  title
                  iconGroup {
                    icon
                    link
                  }
                  footerMenu {
                    title {
                      link
                      name
                    }
                    links {
                      link
                      name
                      foreignLink
                    }
                  }
                  copyright {
                    content {
                      link
                      name
                      value
                    }
                  }
                  buttons {
                    start
                    startLink
                    subscribe
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const { locale, lang } = usePageContext() as any
  const footerName = data.allStrapiFooter.edges.find(({ node }: { node: any }) => {
    /*   if (pageName) return node.name === pageName
    if (!pageName) return node.name === "general" */
    return node.name === "general"
  })

  const footerConfig = footerName.node.content.find((el: any) => {
    return el.language === lang
  })

  const { settings, dispatch } = useStoreon("settings")
  const { language } = settings
  const { languageCode: languageCodeStore } = language

  const setLanguage = (language: Language) => {
    if (language.languageText === "en") {
      if (location.pathname === "/") return
      navigate(`${location.pathname.replace(locale + "/", "/")}`)
    } else {
      if (location.pathname.includes("/" + lang)) return
      navigate(`/${language.languageText}${location.pathname}`)
    }
    dispatch("updateLanguage", language)
  }

  const footerContent = footerConfig.content

  return (
    <footer className={`sova-footer${pageName ? ` sova-footer__${pageName}` : ""}`}>
      <div className="sova-section sova-section_footer sova-section-without-margin-bottom">
        <div className="sova-main-section sova-main-section_footer">
          <div className="sova-section-container sova-section-container_footer">
            <h2 className="sova-section-child sova-h2">{footerContent.title}</h2>
            <h4 className="sova-section-child sova-h4">{footerContent.prehead}</h4>
            <div className="sova-section-container sova-section-container_row sova-section-container_footer sova-section-container_footer-call">
              <a target="blank" href={footerContent.buttons.startLink} id="sova-footer_getStartedBtn">
                <button className="sova-btn sova-btn_primary sova-btn_big">{footerContent.buttons.start}</button>
              </a>
              <Link to={`${locale}${footerContent.href}`} className="sova-section-child sova-section-child_footer-link sova-h4 sova-h4_link">
                {footerContent.link}
              </Link>
            </div>
          </div>
        </div>
        <div className="sova-row sova-row_justify-between sova-row_custom-footer">
          <div className="sova-section-column sova-section-container_footer-subscription">
            <Link to={`${locale !== "" ? locale + "/" : "/"}`} className="sova-logo-footer">
              <img src={logo} className="sova-section-child sova-logo sova-section-img sova-logo_footer sova-mobile-hidden" />
            </Link>
            <p className="sova-section-child sova-section-child_footer sova-p-normal sova-mobile-hidden sova-p-normal_white">{footerContent.p}</p>
            {/* <p className="sova-section-child sova-section-child_footer sova-p-medium sova-p-medium_pink subscription">{footerContent.subsribe}</p> */}
            {/* <FooterSubscriptionForm data={{ button: footerContent.buttons.subscribe, placeholder: footerContent.formPlaceholder }} /> */}
            <div className="sova-section-container sova-section-container_row sova-section-container_footer-socialIcons-group">
              {footerContent.iconGroup.map(({ icon, link }: { icon: any; link: string }, key: number) => (
                <a href={link} target="blank" key={key} className="sova-section-child sova-section-child_footer-socialIcon">
                  <img src={images[icon]} />
                </a>
              ))}
            </div>
          </div>
          <div className="sova-section-container sova-section-container_row sova-section-container_footer-menu">
            {footerContent.footerMenu.map((el: any, i: number) => (
              <div key={i} className="sova-section-child-container sova-section-child-container_footer-menu">
                <p className="sova-section-child sova-section-child_footer sova-p-medium sova-p-medium_pink">{el.title.name}</p>
                <ul className="sova-section-column sova-section-container_footer-menu_block">
                  <Menu items={el.links} className="sova-menu-item-wrapper sova-menu-item-wrapper_footer" textClassName="footer" />
                </ul>
              </div>
            ))}
          </div>
        </div>
        <Copyright className="sova-row sova-row_justify-between sova-row_custom-copyright">
          <div className="sova-row sova_without-margin sova-row_sitemap">
            <ul className="sova-menu">
              <p className="sova-p-tiny sova-p-tiny_highlighted sova-mobile-hidden">&#169; SOVA {year()}</p>
              {footerContent.copyright.content.map(({ name, link, value }: { name: string; link: string; value?: string }, key: number) => (
                <div className="sova-menu-item-wrapper" key={key}>
                  {value ? (
                    <DownLoadLink name={name} lang={lang} value={value} className="sova-menu-link_footer" />
                  ) : (
                    <Link className="sova-menu-item sova-menu-item_white sova-p-normal sova-menu-link_footer" to={`${locale}${link}`}>
                      {name}
                    </Link>
                  )}
                </div>
              ))}
            </ul>
          </div>
          <div className="sova-row sova_without-margin sova-row_language-btn-group">
            {languageConfig.map(({ languageCode, languageText }: Language, i: number) => (
              <button
                className={`sova-language-btn sova-language-btn_${languageText} sova-language-btn_selected-${languageCode === languageCodeStore}`}
                onClick={() => setLanguage({ languageCode, languageText })}
                key={i}
              >
                {languageText}
              </button>
            ))}
          </div>
          <p className="sova-p-tiny sova-p-tiny_highlighted sova-p-tiny_hidden">&#169; SOVA {year()}</p>
        </Copyright>
      </div>
    </footer>
  )
}

export default Footer
