import React from "react"

import { navigate } from "gatsby"
import { useStoreon } from "../../store/store"
import { usePageContext } from "../pageContext/pageContext"

export const languageConfig: Language[] = [
  { languageCode: "ru-RU", languageText: "ru" },
  { languageCode: "en-EN", languageText: "en" },
  // { languageCode: "zh-CN", languageText: "zh" },
]

const LanguageMenu = ({ setShowDropDownStatus, lang }: { setShowDropDownStatus: Function; lang: string }) => {
  const { locale } = usePageContext() as any
  const { dispatch } = useStoreon()
  const setLanguage = (language: Language) => {
    if (language.languageText === "en") {
      if (location.pathname === "/") return
      navigate(`${location.pathname.replace(locale + "/", "/")}`)
    } else {
      if (location.pathname.includes("/" + lang)) return
      navigate(`/${language.languageText}${location.pathname}`)
    }
    dispatch("updateLanguage", language)
  }

  return (
    <div className="sova-section-container-for-dropdown sova-section-container-for-dropdown_language">
      <div className={`sova-menu sova-dropdown-menu_language sova-dropdown-menu-desktop`}>
        {languageConfig.map(({ languageCode, languageText }: Language, i: any) => (
          <div
            className={`sova-menu-item-language`}
            id={`language-${languageText}`}
            key={i}
            onClick={() => {
              setShowDropDownStatus(false)
              setLanguage({ languageCode, languageText })
            }}
          >
            {languageText.toUpperCase()}
          </div>
        ))}
      </div>
    </div>
  )
}

LanguageMenu.displayName = "LanguageMenu"

export default LanguageMenu
