import React from "react"
import { Link } from "gatsby"
import { usePageContext } from "../pageContext/pageContext"
import foreignLinkIcon from "../../assets/icons/foreign-link-icon.svg"
import foreignLinkIconWhite from "../../assets/icons/foreign-link-icon-white.svg"

interface Props {
  className?: string
  textClassName?: string
  items?: any
}

const Menu = ({ className, items, textClassName }: Props) => {
  const { locale } = usePageContext() as any
  return items.map(({ link, name, describtion, foreignLink }: { link: string; name: string; describtion: string; foreignLink: boolean }, i: number) => (
    <li className={`${className ? className : ""} ${link === "#" ? "sova-menu-item_disabled" : ""}`} key={i}>
      {(link === "#" && (
        <p className={`sova-menu-link sova-menu-link_disabled ${textClassName ? `sova-menu-link_${textClassName}_disabled` : ""}`}>
          <span>{name}</span> <sup className="sova-menu-link_disabled-soon">SOON</sup>
          {describtion && <span className="sova-menu-link_describtion">{describtion}</span>}
        </p>
      )) ||
        (foreignLink && (
          <a href={link} target="blank" className={`sova-menu-link ${textClassName ? `sova-menu-link_${textClassName}` : ""}`}>
            <span>{name}</span>
            <img src={`${textClassName === "footer" ? foreignLinkIconWhite : foreignLinkIcon}`} className={`sova-icon sova-icon_foreignLink sova-icon_foreignLink_${textClassName}`} />
            {describtion && <p className="sova-menu-link_describtion">{describtion}</p>}
          </a>
        )) || (
          <Link to={`${link === "/mainDocPage" ? link : locale + link}`} className={`sova-menu-link ${textClassName ? `sova-menu-link_${textClassName}` : ""}`}>
            <span>{name}</span>
            {describtion && <p className="sova-menu-link_describtion">{describtion}</p>}
          </Link>
        )}
    </li>
  ))
}

export default Menu
