import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { usePageContext } from "../pageContext/pageContext"
interface Props {
  description?: string
  meta?: []
  title: string
}

const SEO = ({ description, meta, title }: Props) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            defaultLanguage
            description
            supportedLanguages
            title
          }
        }
        strapiSeos {
          SEO {
            meta_item {
              content
              property
              name
            }
            language
          }
        }
      }
    `
  )
  const { lang } = usePageContext() as any
  const SEO = data.strapiSeos.SEO.find((el: any) => {
    return el.language === lang
  })
  const metaData = SEO.meta_item.map((el: any) => {
    if (el.content === "title") return { ...el, content: title }
    if (description && el.content === "description") return { ...el, content: description || data.site.siteMetadata.description }
    if (el.property === null) return { content: el.content, name: el.name }
    if (el.name === null) return { content: el.content, property: el.property }
    return el
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={metaData.concat(meta || [])}
    />
  )
}

export default SEO
