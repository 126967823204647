import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const DownLoadLink = ({ name, lang, value, className }: { name: string; lang: string; value: string; className?: string }) => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { in: ["pdf", "zip"] } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `)

  const file = allFile.edges.find(({ node }: any) => node.name === `${value}-${lang.toUpperCase()}` || node.name === name)

  return (
    <a className={`sova-menu-item sova-menu-item_white sova-p-normal ${className}`} href={file?.node?.publicURL} download>
      {name}
    </a>
  )
}

export default DownLoadLink
