import React from "react"

interface Props {
  children?: React.ReactNode
  className?: string
}

const Copyright = ({ children, className }: Props) => <div className={className}>{children}</div>

export default Copyright
