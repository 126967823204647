import React from "react"

interface Props {
  children?: any
  className?: string
}

const Section = ({ children, className }: Props) => <section className={`${className ? className : ""}`}>{children}</section>

Section.displayName = "Section"

export default Section
